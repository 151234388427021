a, a.btn, .btn, .icon, button { transition: $global-transition; }
a:hover, .btn:hover, .icon:hover, button { transition: $global-transition; }

a.btn, .btn, a.button, .button, .nav-links a, .mailpoet_submit, input[type=submit] { 
	transition: $global-transition;
	display: inline-block;
	margin-bottom: 0; // For input.btn
	font-weight: $btn-font-weight;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none; /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214 */
	border: 2px solid transparent;
	white-space: wrap;
	@include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $border-radius-base); 
	@include user-select(none);

	margin-bottom: 1em; 
	padding: .4em 1em;
	border-weght: 2px;
	border-color: $brand-secondary;
	border-radius: 5px;
	background: $brand-secondary;
	color: #fff;
	font-family: $brand-font;
	text-transform: uppercase;
	font-size: .9em;
	line-height: 1.2em;
	white-space: normal;
	font-weight: 600;
	text-decoration: none;
	@include anti-alias;
	
	&,
	&:active,
	&.active {
		&:focus,
		&.focus {
			@include tab-focus;
		}
	}

	&:hover,
	&:focus,
	&.focus {
		color: $brand-secondary;
    	background: none;
    	border-color: $brand-secondary;
    	text-decoration: none;
  	}

  	&:active,
  	&.active {
    	outline: 0;
    	background-image: none;
    	@include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  	}

  	&.disabled,
  	&[disabled],
  	fieldset[disabled] & {
   		cursor: $cursor-disabled;
   		pointer-events: none; // Future-proof disabling of clicks
    	@include opacity(.65);
    	@include box-shadow(none);
  	}
}

.content-wrap {
	a.btn, .btn, a.button, .button, .nav-links a, .mailpoet_submit, input[type=submit] {
		/* Chevron Right Icon */
		&:after {
			@include fa;
			content: '';
			padding-left: .5em;
		}
		/* -- */
	}
}

/* Default Button */
a.btn-default { 
	background: none; 
	color: $brand-secondary; 
	border-color: $brand-secondary;
	text-decoration: none; 
	&:hover { color: #fff; background: $brand-secondary; }
}

/* Primary Button */
a.btn-primary {
	background-color: $brand-primary;
	border-color: $brand-primary;
	color: #fff;
}
a.btn-primary:hover, .mailpoet_submit:hover {
	background-color: none;
	border-color: $brand-primary;
	color: $brand-primary;
}

/* Secondary Button */
a.btn-secondary {
	color: #fff;
	background-color: $brand-secondary;
	border-color: $brand-secondary;
	&:hover { background-color: lighten( $brand-secondary, 10% ); border-color: darken( $brand-secondary, 10% ); color: #fff; }
}

/* Tertiary Button */
a.btn-tertiary, .nav-links a {
	color: #fff !important;
	text-decoration: none !important;
	background-color: $brand-tertiary;
	border-color:$brand-tertiary;
	&:hover { background-color: darken( $brand-tertiary, 10% ); border-color: darken( $brand-tertiary, 10% ); color: #fff; }
}

/* Quaternary Button */
a.btn-quaternary {
	color: #fff !important;
	text-decoration: none !important;
	background-color: $brand-quaternary;
	border-color:$brand-quaternary;
	&:hover { background-color: darken( $brand-quaternary, 10% ); border-color: darken( $brand-quaternary, 10% );; color: #fff; }
}
a.btn-primary:visited, a.btn-secondary:visited, a.btn-tertiary:visited, a.btn-quaternary:visited, a.button:visited, .button:visited { color: #fff; }

/* White Button */
a.btn.btn-white {
	color: #fff;
	border-color: #fff;
	background: none;
	&:hover{
		background: #fff;
		color: $brand-primary;
		border-color: #fff;
	}
}
/* -- */


/* Misc */
a.btn-table { display: table; }

a.btn-lg { font-size: 1.4em; }
a.btn-md { font-size: 1.2em; }

a.btn .fa,
a.btn .glyphicon-fa { 
	padding: 0 .2em; 
	font-size: 1.3em;
}

.btn .glyphicon, .tab-content .glyphicon { padding-right: 8px; }
/* -- */


/* Page Numbers */
.page-numbers.current { 
	padding: .6em 1.2em;
	border: 1px solid transparent;
	margin: 0 0 1em;
	display: inline-block;
	line-height: 1.428571429;
	color: #fff;
	vertical-align: middle;
	background: #999;
	font-size: 1em;
 }
.page-numbers.dots { color: #999; }

/* Post Navigation Links */
.nav-links .nav-previous, .nav-links .nav-next { display: inline-block; margin-right: 15px; }


/* Page Tabs / Nav Tabs */
.nav-tabs { 
	margin-top: 1.5em;
	border-bottom: 1px solid rgba($brand-tertiary,.3);
	> li > a { 
		padding: .5em .75em;
		border: none; 
		text-decoration: none !important;
		font-family: $brand-font;
		margin: 0;
		color: $brand-secondary;
		border-radius: 0;
	} 
  	> li > a:hover, .nav-tabs > li > a:focus { 
		color: #fff;
		background-color: $brand-tertiary;
	}
	> li.active > a, > li.active > a:focus, > li.active > a:hover {
		color: #fff;
		background: $brand-primary;
		border: none;
		border-radius: 0;
		font-weight: bold;
	}
	.dropdown-menu { font-family: $brand-font; }
}
.nav-tabs + .tab-content {
	padding-left: 30px;
	padding-right: 30px;
	border: 1px solid rgba($brand-tertiary,.15);
	background: #fff;
}

@media (min-width: $screen-md-min) {
	/* Stacked Tabs */
	.nav-tabs.stacked {
		margin: 1.5em 0 0;
		padding-right: 15px;
		border-right: 1px solid rgba($brand-quaternary,.1);
		background: none;
		float: left;
		width: 30%;
		border-bottom: none;
		> li { float: none; }
		> li.active a {
			box-shadow: none;
			border: none;
			font-weight: bold;
			color: $brand-tertiary;
			background: rgba($brand-tertiary,.15);
		}
		> li a { 
			padding: .3em .5em;
			border: none;
			border-radius: 0; 
			color: $brand-tertiary;
		}
	}
	.nav-tabs.stacked + .tab-content {
		margin-top: 1.5em;
		padding-top: 0;
		padding-left: 15px;
		padding-right: 15px;
		float: left;
		width: calc(70% - 15px);
		box-shadow: none;
		border: none;
	}
}
/* ---- */


/* Document Tabs / Nav Pills+Nav Tabs */
.nav-tabs.nav-pills { 
	border-bottom: 1px solid rgba($brand-primary, .5); 
	> li { background: #fff; }
	> li > a { 
		color: $brand-secondary;
		border: 1px solid rgba($brand-secondary,.3);
		border-bottom: 1px solid rgba($brand-primary, .5);
		background: rgba($brand-secondary,.15);
		border-radius: 4px 4px 0 0;
		text-decoration: none !important;
		font-family: $brand-font;
		&:hover { 
			color: $brand-primary;
			background: rgba($brand-primary,.1); 
			border: 1px solid rgba($brand-primary,.3); 
		}
	}
	> li.active > a { 
		color: #fff;
		background: $brand-primary;
		border-color: $brand-primary;
	}
	~.tab-content { border: 1px solid rgba($brand-primary,.5); border-top: none; box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.08); }
	~.tab-content table { margin: 0; }
}
.tab-content {
	.glyphicon { color: rgba($brand-primary,.7); }
	a:hover { color: $brand-secondary; }
}
/* -- */