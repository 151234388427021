/* DEFAULT PAGES */
#frame { background: $body-background; }
#pushobj {
	margin: 0 auto;
	/*max-width: 2300px;*/
	position: relative;
}
#body-wrap { 
	position: relative; 
	z-index: 1; 
	top: 6em;
	background: $body-background;
	.content { position: relative; z-index: 2; }
}

.content-wrap, .block-wrap { padding-top: 1.5em; padding-bottom: 3em; }
.content-wrap .main { margin-bottom: 3em; }

.white-block { 
	background: #fff; 
	padding: 1em 0; 
	box-shadow: 0 0 8px rgba(0,0,0,.15);
}

.down-arrow { 
	display: block;
	height: 50px;
	width: 100%;
	background: url('../images/arrow-mask.png') no-repeat 50% 0 / cover;
	position: absolute;
	bottom: 0;
	left: 0;
	text-indent: -999em;
}

@media (max-width: $screen-sm-max) {
	body.page-template-template-home,
	body.page-template-template-full-width {
		.main, .main.sidebarleft, 
		.main.sidebarright, .sidebar, 
		.sidebar-primary .main, 
		.sidebar.sidebarleft, 
		.sidebar.sidebarright { 
			padding-left: 0;
			padding-right: 0;
		}
	}
	#block, body.home #block { 
		padding-left: 15px; 
		padding-right: 15px; 
		padding-top: 1.5em; 
	}
	
	#body-wrap { top: 5em; }
	.content-wrap { }
	
	.down-arrow { 
		height: 25px;
		background: url('../images/arrow-mask_sml.png') no-repeat 50% 0 / cover;
	}
}

@media (max-width: $screen-xs-max) {
	#body-wrap { top: 3em; }
}
/* -- */


/* Sub Image*/
$subimage_lg: 432px; /* Large Sub-Image Height */
$subimage_sm: 285px; /* Small Sub-Image Height */ 

#subimage { 
	position: relative;
	height: $subimage_lg;
	background: $brand-tertiary;
	max-width: 1400px;
	margin: 0 auto;
	overflow: hidden;
	.subimage { 
		height: $subimage_lg;
		background-size: cover;
		background-position: 50% 50%;
		img { width: 100%; }
	}
	.subimage-header {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+25,0.5+70 */
		background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0.5) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 25%,rgba(0,0,0,0.5) 70%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 25%,rgba(0,0,0,0.5) 70%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
		
		.subimage-header-wrapper {
			margin-bottom: 2em;
			position: absolute;
			bottom: 0;
		}
		h1 { 
			margin: 0;
			color: #fff; 
			font-family: $brand-font;
		}
		.breadcrumb {
			background: none;
			padding: 0; 
			margin: 0;
			color: rgba(255,255,255,.5);
			font-size: .8em;
			.breadcrumb_last { color: #fff; }
			a, a:visited { color: rgba(255,255,255,.65); }
		}
	}
}

@media (max-width: $screen-sm-max) {
	#subimage {
		height: $subimage_sm; 
		.subimage { max-height: $subimage_sm; }
		.subimage-header-wrapper { }
		.subimage-header { font-size: .8em; }
		.breadcrumb { font-size: 1em; }
	}
}

@media (min-width: $screen-md-min) {
	#subimage .subimage {
		//@include image-animated;
	}
}
/* -- */


/* Breadcrumbs */
.breadcrumb-bar {
	width: 100%;
	padding: .6em 0 .4em;
	background: $brand-tertiary;
	font-size: 1.1em;
	.breadcrumb, .breadcrumb a, .breadcrumb a:visited {
		color: rgba(255,255,255,.75);
	}
	.breadcrumb a:hover { color: #fff; }
}
.breadcrumb {
	background: none;
	padding: 0; 
	margin: 0;
	color: rgba($brand-quaternary,.5);
	font-family: $brand-font;
	font-weight: 600;
	font-size: .8em;
	text-transform: uppercase;
	a, a:visited { color: rgba($brand-quaternary,.5); text-decoration: none; }
	a:hover { text-decoration: underline; }
	.breadcrumb_last { color: $brand-quaternary; }
}
.page-header { 
	margin: .3em 0 0; 
	padding: 0;
	border: none; 
	color: $brand-tertiary; 
	h1, h2 { margin: 0 0 .25em; font-size: 2.6em; color: $brand-quaternary; }
}
/* -- */


/* Portal Btns */
.portal-feature {
	position: relative;
	margin-top: -40px;
	z-index: 10;	
}
.portal-btns {
	margin-bottom: 1em;
	position: relative;
	padding: .75em 0;
	>.col { 
		padding: 0;
		min-height: 6.5em;
	}
	.col:last-child { border: none; }
	.portal-btn {
		font-family: $brand-font;
		font-size: 1.8em;
		line-height: 1em;
		font-weight: 600;
		@include anti-alias;
		a {
			position: relative;
			overflow: hidden;
			margin-top: .25em;
			padding: .75em 1em;
			color: #fff;
			display: block;
			background: none !important;
			transition: $global-transition;
			&:before {
				content: '';
				position: absolute;
				width: 1100%; 
				height: 1100%;
				top: 10px; 
				right: -500%;
				transform-origin: 54.2% 0;
				transform: rotate(45deg);
				z-index: -1;
				background: $brand-primary;	
			}
			span.caption { 
				display: block;
				line-height: 1em;
				font-weight: 300;
				font-size: .7em;
				padding-top: .3em;
			}
			&:hover {
				margin-top: 0;
				color: $brand-secondary;
				padding: .75em 1em 1em;
			}
		}
		a.primary-bg:before { background-color: $brand-primary; }
		a.secondary-bg:before { background-color: $brand-secondary; }
		a.tertiary-bg:before { background-color: $brand-tertiary; }
		a.quaternary-bg:before { background-color: $brand-quaternary; }
		a.primary-light-bg:before { background-color: tint($brand-primary,40%); }
		a.secondary-light-bg:before { background-color: tint($brand-secondary,40%); }
		a.tertiary-light-bg:before { background-color: tint($brand-tertiary,40%); }
		a.quaternary-light-bg:before { background-color: tint($brand-quaternary,40%); }
	}
}
@media (max-width: $screen-sm-max) {
	.portal-btns .portal-btn .title {
		font-size: 1.3em;
	}
}
/* -- */


/* Feature Children */
#feature-children {
	margin-top: 1.5em;
	.well {
		margin-bottom: 2em;
		padding: 0;
		border: none;
		border-radius: 0;
		background: #fff;
		box-shadow: 0 0 10px rgba(0,0,0,.1);
		.feat-thumb {
			img { width: 100%; }
			border-bottom: 4px solid $brand-secondary;
		}
		.feat-header {
			padding: .25em 1em; 
			.entry-title { 
				margin: .5em 0 .25em;
				font-family: $brand-font; 
				font-size: 1.2em;
				font-weight: 600; 
				color: $brand-primary;
				a { text-decoration: none; color: $brand-primary; }
			}
		}
		.entry-summary {
			padding: 0 1.2em .2em;
			font-size: .85em;
			line-height: 1.4em;
		}
	}
	.well:hover { }
}
.feature-children-block {
	padding: 3em 0;
	background: rgba($brand-quaternary,0);
	position: relative;
	&:after {
		position: absolute;
		z-index: -1;
		top: 0;
		content: '';
		background: url('../images/trees-bg.jpg') no-repeat 50% 50% / cover;
		width: 100%;
		height: 100%;
		opacity: .1;
		@include desaturate;
	}
	.well { margin: 0 .75em !important; }
}
/* -- */


/* Feature Grid
.feature-grid {
	position: relative;
	.item > a:hover > .feature-item {
		box-shadow: 0 0 6px rgba(0,0,0,.2);
	}
	.feature-item { 
		position: relative; 
		background: $brand-tertiary;
		text-align: right;
		transition: $global-transition;
	}
	.feature-header {
		background: $brand-secondary;
		color: #fff;
		font-family: $brand-font;
		font-size: 1em;
		text-align: center;
		padding: .5em .8em;
		position: absolute;
		width: 100%;
		bottom: 0;
		h4 { 
			color: #fff;
			margin: 0;
		}
	}
	.feature-grid-scroll-control {
		position: absolute;
		height: 4em;
		width: 100%;
		top: 35%;
		.control { font-size: 4em; color: rgba(0,0,0,.1); }
		.control:hover { color: rgba(0,0,0,.2); }
		#feature-grid-prev {
			position: absolute;
			left: -3%;
		}
		#feature-grid-next {
			position: absolute;
			right: -3%;
		}
	}
	#feature-grid-pager {
		margin-top: 1em;
		text-align: center;
		a {
			background: rgba($brand-tertiary,.15);
			border: 1px solid rgba($brand-tertiary,.2);
			box-shadow: 1px 1px 0 #fff, inset 1px 1px 0 rgba(0,0,0,.1);
			width: .75em;
			height: .75em;
			border-radius: 10em;
			margin: 0 .5em 0 0;
			display: inline-block;
			&:hover { background: rgba($brand-secondary,.3); }
			span {
				display: none;
			}
		}
		a.selected {
			background: rgba($brand-tertiary,.3);
  			cursor: default;
		}
	}
}
@media (max-width: $screen-sm-max) {
	.feature-grid .feature-header { font-size: .9em; }
	.feature-grid-scroll-control { display: none; }
}
/* -- */


/* Feature Content */
.feature-block-wrapper {
    .feature-block {
        position: relative;
        background: $brand-tertiary;
        &.primary-bg, &.secondary-bg, &.quaternary-bg, &.custom-dark-bg {
            color: #fff;
            p.lead { color: #fff; }
        }
        &.primary-bg { 
            h1, h2, h3, h4, h5, h6 { color: #fff; }
        }
        > .container {
            position: relative;
            z-index: 2;
            overflow: hidden;
        }
        .bg {
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            opacity: .07;
            content: "";
        }
        .col-image { 
            position: absolute;
            // bottom: 0;
            // right: 0;
            width: 100%;
            //> div { float: right; }
        }
        .feature-block-content {
            padding-top: 4em;
            padding-bottom: 4em;
            position: relative;
            z-index: 1;
        }
        .feature-content-image { 
            //position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
        }
        @media only screen and (min-width: $screen-md-min) {
            &-1 {
                max-height: 550px;
                overflow: hidden;
            }
        }
    }
}

.feature-block-alt { 
	background: $brand-primary;
	//.col-image > div { float: left; }
	.feature-content-image { 
		left: -1.5em;
		right: auto;
	}
}

@media (max-width: $screen-sm-max) {
	.feature-block {
		padding: 0;
		.col-image { position: relative; }
		.feature-content-image { 
			// position: relative;
			// top: auto;
			// left: auto;
			// right: auto;
            width: 100%;
            max-height: 500px;
            object-fit: cover;
            object-position: top;
		}
	}
}

.opening-block {
	.bg {
		@include desaturate;
		&:before {
			@include gradient-bottom-top(#fff,1,0%,#fff,0,100%);
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
		}
	}
	.feature-block-content { padding-top: 1.5em; }
}
/* -- */


/* Feature Card */
.feature-card {
	background: #fff;
	box-shadow: 0 0 6px rgba(0,0,0,.2);
	position: relative;
	overflow: hidden;
	margin-bottom: 1em;
	/*&:after {
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0;
		right: -30px;
		content: "";
		background: url('../images/cltc-flame.png') no-repeat 100% 100%;
		opacity: .1;
	}*/
	> * { position: relative; z-index: 1; }
	.image { 
		width: 45%;
		float: left;
		background-color: $brand-tertiary;
	}
	.caption {
		width: 55%;
		float: left;
		padding: 1em 1.5em;
	}
}
/* -- */


/* HOME PAGE */
body.page-template-template-home,
body.page-template-template-full-width {
	.main { 
		padding-left: 0;
		padding-right: 0;
	}
	.content-wrap { margin-top: 0; padding-bottom: 0; }
}
body.page-template-template-home .content-wrap { padding-top: 4em; }

#news-dash { 
	position: relative;
	z-index: 1;
	border-top: $brand-primary solid 15px;
	
	/* Tab Controls */
	> .nav-tabs {
		margin: 0;
		background: none;
		border-top: 1px solid rgba($brand-quaternary,.15);
		border-bottom: 1px solid rgba($brand-quaternary,.15);
	}
	.nav-tabs.nav-pills {
		margin: 0;
		border: none;
		font-size: 1.3em;
		box-shadow: none;
		background: none;
		text-align: center;
		> li { 
			margin: 0; 
			display: inline-block;
			float: none;
		}
		> li > a {
			padding: .5em 1em;
			color: $brand-quaternary;
			background: none;
			border: none;
			border-radius: 0;
			text-align: center;
			font-family: $body-font;
			font-weight: 600;
			@include anti-alias;
			&:hover {
				color: $brand-primary;
				background: tint($brand-primary,20%,.1);
			}
		}
		> li.active > a:focus,
		> li.active > a:hover, 
		> li.active > a {
			margin: 0;
			background: rgba($brand-primary,.6) !important;
			border-color: rgba($brand-primary,.6) !important;
			color: #fff !important;
			box-shadow: none;
		}
	}
	.tab-content {
		padding: 2.5em 0; 
		position: relative;
		z-index: 0;
		box-shadow: none;
		border: none;
		/*
		&:after {
			position: absolute;
			z-index: -1;
			width: 100%;
			height: 100%;
			top: 0;
			content: "";
			opacity: .1;
			background: url('../images/news-bg.jpg') no-repeat 0 0 / cover;
		}
		*/
	}
	/* -- */
	
	
	/* Events Widget */
	.widget.events {
		.widget.widget_ai1ec_agenda_widget {
			margin: 0;
			border: none;
			box-shadow: 0 0 10px rgba(0,0,0,.1);
			h4 {
				margin: 0 0 .5em;
				padding: .5em .75em;
				color: #fff;
				font-size: 1.4em;
				background: $brand-primary;
				position: relative;
				&:after { 
					top: -14px;
					right: 0;
					position: absolute;
					width: 100%;
					height: calc(100% + 14px);
					background: url('../images/corner-motif.png') no-repeat top right;
					content: '';
				} 
			}
		}
		.ai1ec-agenda-widget-view {
			.ai1ec-event {
				.ai1ec-event-title {
					font-size: 1.4em;
					color: #231f20;
				}
				.ai1ec-event-time { 
					color: $brand-secondary;
					font-weight: bold;
				}
			}
			.ai1ec-month, .ai1ec-day, .ai1ec-weekday {
				color: $brand-secondary;
				font-size: 1.2em;
				font-weight: bold;
			}
			.ai1ec-allday-badge {
				background: $brand-secondary;
			}
		}
	}
	/* -- */
	
	/* News Tab */
	.news-block {
		h3, h4 { 
			color: #231f20;
			margin-bottom: .7em; 
			a { color: $brand-primary; } 
		}
		h3 { font-size: 1.8em; }
		h4 { font-size: 1.3em; }
		.newsletter-widget {
			ul.list-group .list-group-item:nth-of-type(2n+1) { background: rgba( $brand-primary, .05 ); }
		}
	}
	/* -- */
	
	/* Social Tab */
	.social-block {
		padding-bottom: 2em;
		.social-icons {
			float: right;
			font-size: 1.6em;
			margin-left: .8em;
		}
	}
	/* -- */
	
	/* Newsletters Tab */
	#publications-view {
		.newsletter {
			background: tint($brand-secondary,80%);
			&:hover { background: tint($brand-secondary,70%); }
		}
	}
	/* -- */
	
}

@media (max-width: $screen-sm-max) {
	body.page-template-template-home {
		.content-wrap { padding-top: 0; }
	}
}
/* -- */


/* CONTACT PAGE */
body.page-template-template-contact .main .campus-info { width: 100%; float: none; }
/* -- */


/* Slick Slider */
.slick-slider {
	margin: 0;
	padding-left: 0;
	padding-right: 0;
	.slick-prev, .slick-next {
		z-index: 1;
		opacity: .6;
		&:before {
			color: $brand-secondary;
			opacity: 1;
		}
		&:hover {
			opacity: 1;
		}
	}
	.slick-dots {
		position: relative;
		padding: 0;
		li button:before {
			color: rgba($brand-secondary, .4);
		}
		li.slick-active button:before {
			color: $brand-secondary;
		}
	}
}
/* -- */

/* Subscription Block */
.subscriber-block {
	position: relative;
	color: #fff;
	background: $brand-secondary;
	/*
	&:before {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background: url('../images/watermark.png') no-repeat -5% 50%;
		background-size: 350px;
		content: "";
		z-index: 0;
		opacity: .05;
	}
	*/
	.mailpoet_paragraph { 
		display: inline-block; 
		float: left; 
		padding-right: 6px; 
	}
	.mailpoet_message {
		color: #fff;
	}
	.mailpoet_submit {
		float: left; 
	}
}
.subscriptions {
	padding: 1em 2em;
	h4 { 
		margin-top: .5em;
		color: #fff; 
		font-family: $brand-font; 
		font-size: 2em; 
	}
	.description { 
		color: #fff;
		display: clear; 
		margin-bottom: .5em; 
	}
	.mailpoet_paragraph { 
		margin-bottom: .5em;
	}
	p.mailpoet_paragraph { display: inline-block; }
	input[type="text"],input[type="email"] { 
		font-size: 1em; 
		color: #bdbdbd; 
		padding: .4em .8em; 
		border: none;
		border-radius: .3em;
		box-shadow: none;
		height: auto; 
	}
	.mailpoet_message {
		clear: both;
	}
	.mailpoet_submit { 
		margin: 0; 
		font-size: 1.1em; 
		padding: .2em .6em; 
		border: 2px solid $brand-primary;
		background: $brand-primary;
		&:hover {
			background: $brand-secondary;
			border-color: #fff;
			color: #fff;
		}
	}
}
/* -- */


/* Documents */
.documents {
	h4 { margin: 0 0 1em; }
	a { text-decoration: none !important; }
	.document-card {
		position: relative;
		margin:  .5em 0 1em;
		padding: 15px 17px;
		min-height: 6.5em;
		font-family: $brand-font;
		background: #fff;
		box-shadow: 0 0 10px rgba(0,0,0,0);
		transition: $global-transition;
		&:hover {
			box-shadow: 0 0 10px rgba(0,0,0,.15);
			margin: 0 0 1.5em;
		}
		&:before {  
			position: absolute; 
			top: -2px; 
			right: 15px;
			content: "\f105"; /* fa-angle-right */
			font-family: 'FontAwesome'; 
			color: rgba($brand-tertiary,.2); 
			font-size: 5em;
			line-height: 1em; 
			transition: $global-transition;
		}
		span { display: block; }
		span.link { 
			width: 80%;
			color: $brand-primary;
			font-size: 1.2em;
			line-height: 1.2em;
			font-weight: bold;
			@include anti-alias;
			a { color: $brand-primary; text-decoration: none; }
		}
		span.meta {
			font-size: .9em;
			padding-top: .3em;
			color: $body-color;
		}
		
	}
}
@media (max-width: $screen-sm-max) {
	.documents .document-card { min-height: auto; }
}
/* -- */


/* Misc */
.mid-feature {
	padding: 3em 0;
}

/* Tabs */
.tab-content {
	padding: 1.5em 0;
}
#documentsContent { 
	padding: 0; 
}