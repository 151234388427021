$admin-bar: 2em;
$brand-width: 196px;

.navbar-fixed-bottom, .navbar-fixed-top { position: fixed !important; }
@media only screen and (min-width: $screen-xs-max) {
	body.admin-bar #sticky-navigation.sticky.navbar-fixed-top { top: $admin-bar; }
}

.header-wrapper { 
	position: absolute; 
	width: 100%; 
	z-index: 10;
	background: $brand-primary;
}
header#header {
	position: relative;
	z-index: 20;
	width: 100%;
	color: #fff;
	.brand {
		max-width: $brand-width;
		position: absolute;
		top: 0;
		img { width: 100%; }
	}
	/* Portal Nav 
	div.portal-nav {
		float: right;
		color: $brand-tertiary;
		//font-size: 13px;
		font-family: $brand-font;
		font-weight: 600;
		@include anti-alias;
		a { 
			color: $brand-tertiary;
			text-decoration: none; 
			text-transform: initial;
		}
		.heading { float: left; padding: 7px 14px 0 0; }
		.nav.portal-nav {
			border-left: 1px solid rgba(255,255,255,.1);
			border-bottom: 1px solid rgba(255,255,255,.1);
			display: inline-block;
			float: left;
			> li {
				float: left;
				display: inline-block; 
				padding: 0;
				border-right: 1px solid rgba(255,255,255,.1);
				> a { 
					padding: 7px 13px; 
					&:hover {
						background: none;
						color: #fff;
					}
				}
			}
	  	}
	  	h1.headh1 {
			font-family: $brand-font;
			color: rgba(255,255,255,.5);
			font-size: 12px;
			font-weight: bold;
			text-transform: capitalize;
			line-height: 18px;
			text-align: right;
			margin: 8px 0;
			height: 18px;
			overflow: hidden;
			@include anti-alias;
		}
	}
	*/
}
		
		
	/* Top Head */
	.top-head {
		border-bottom: 1px solid rgba(#fff,.1);
		margin-bottom: 1em; //4.33em;
		.top-links { float: right; }
		.btn { 
			color: #fff;
			font-weight: 600;
			display: inline-block; 
			float: left; 
			margin: 0;	
			font-size: 16px;
			line-height: 16px;
			padding: 8px 12px;
			height: 38px;
			background: $brand-secondary;
			border-color: $brand-secondary;
			border-radius: 0;
			text-transform: capitalize;
			.fa { font-size: 16px; }
			&:hover {
				background: lighten($brand-secondary,10%);
				border-color: lighten($brand-secondary,10%);
				color: #fff;
			}
		}
		#menu-quick-links {
			float: left;
			display: inline-block;
			> li > a { 
				color: #fff;
				font-weight: 600;
				font-size: 16px;
				line-height: 21px;
				padding: 8px 11px 8px 12px;
				height: 38px;
				background: $brand-quaternary;
				border-color: $brand-quaternary;
				@include anti-alias;
				text-transform: capitalize;
				&:hover {
					background: darken($brand-quaternary,10%) !important;
					border-color: darken($brand-quaternary,10%) !important;
					color: #fff;
				}
			}
			> li > .dropdown-menu { 
				background: $brand-quaternary; 
				a { color: #fff; 
					&:hover { background: darken($brand-quaternary,10%) !important; } 
				} 
			}
			.caret { display: inline-block; color: rgba(255,255,255,.5); }
		}
		.btn-search { 
			width: 38px; 
			padding: 10px 0;
			color: #fff;
			background: rgba(#000,.4);
			border: none;
			&:hover {
				color: #fff;
			}
		}
		.social {
			float: left;
			a {
				display: inline-block;
				padding: 10px 0;	
				font-size: 16px;
				line-height: 17px;	
				height: 38px;
				width: 38px;
				color: #fff;
				text-align: center;
				text-decoration: none;
			}
			a:link, a:visited { background: tint($brand-primary,20%) !important; }
			img { height: 100%; }
		}
	
		.contact-col {
			float: left;
			padding-top: .5em;
			.contact-link { 
				padding-right: .75em;
				display: inline-block;
				font-size: 16px;
				line-height: 20px;
				text-align: right;
				font-family: $brand-font;
				font-weight: bold;
				@include anti-alias;
				.fa { color: #fff; padding-right: .3em; transition: $global-transition; }
				a { 
					color: #fff; 
					&:hover { 
						color: $brand-tertiary; 
						.fa { color: $brand-tertiary; } 
					}
				}	
			}
		}
		
		#google_translate_element {
			margin: 5px 4px 3px 8px;
			//padding: 4px 10px;
			float: left;
			a.goog-te-menu-value, a.goog-te-menu-value:visited { 
				font-family: $brand-font;
				color: #fff !important;
				span { color: rgba(#fff,.5) !important; border-color: rgba(#fff,.3) !important; transition: $global-transition; }
				span:first-child { color: #fff !important; padding: 0 4px 0 2px; }
				&:hover { 
					color: $brand-secondary !important;
					span { color: $brand-secondary !important; }
					span:last-child { color: $brand-secondary !important; }
				}
			}
			.goog-te-gadget-simple {
				background: none;
				border: none;
			}
			.goog-te-gadget-icon {
				background: url('../images/google.png') no-repeat 0 0 / cover !important;
				width: 15px;
				height: 15px;
			}
		}

		/* Search */
		#searchFormContainer {
			margin-top: .75em;
			width: 320px;
			display: none;
			.searchform {
				position: relative;        
			}
			.searchform input[type="text"] {
				float: left;
				font-size: 1.1em;
				height: 2.2em;
				color: $brand-tertiary;
				border-radius: .5em;
				border: 2px solid rgba($brand-tertiary,.1);
				box-shadow: none;
			}
			.searchform .btn {
				color: $brand-secondary;
				font-size: 1.1em;
				background: none;
				border: none;
				display: block;
				margin: 0;
				padding: .35em .5em;
				position: absolute;
				right: 0;
				.fa { padding-right: 3px; }
				span { display: none; }
			}
			.searchform .btn:hover { background: $brand-secondary; color: #fff; }
		}
	}
	/* -- */

.social {
	float: left;
	a {
		float: left;
		display: inline-block;
		padding: 10px;	
		font-size: 20px;
		line-height: 1em;
		height: 42px;
		width: 42px;
		color: #fff;
		text-align: center;
		background: $brand-tertiary;
		text-decoration: none;
	}
}

nav.navigation {
	.menu-logo { 
		display: none; 
		margin: 10px;
		width: 95px;
		float: left;
		a { display: block; img { width: 100%; } }
		.white-logo {display: none; }
	}
}


@media only screen and (max-width: $screen-md-max) and (min-width: $screen-md-min) {
	nav.navigation .navbar-nav > li > a {
		font-size: .9em;
	}
}
@media only screen and (max-width: $screen-sm-max) {
	header#header {
		padding: 0;
		.top-head { margin-bottom: 0; }
		.brand { 
			max-width: 110px;
			width: auto;
		}
	}
	#sticky-navigation {
		border-top: none !important;
		border-bottom: 4px solid $brand-tertiary;
	}
	nav.navigation .menu-logo { 
		/* display: block; */
	}
	.extras-col { display: none; }
	#body-wrap { padding-top: 0 !important; }
}
@media only screen and (max-width: $screen-xs-max) {
	header#header {
		.top-head .head-phone { }
	}
}
@media only screen and (min-width: $screen-md-min) {
	#header-navigation {
		.header-hidden { display: none; }
	}
}


/* NAVIGATION */
#sticky-navigation {}
#sticky-navigation.sticky.navbar-fixed-top { 
	display: block;
	background: $brand-primary;
	color: #fff;
	.navbar-nav {
		> li.current-menu-parent > a {
			color: tint($brand-primary,60%);
			border: none;
		}
		> li > a { 
			padding: .75em;
			color: #fff; 
		}
	}
	nav.navigation .menu-logo {
		.color-logo { display: none; }
		.white-logo { display: block; }
	}
}
nav.navigation .navbar-primary-container { 
	font-family: $brand-font;
	font-weight: 600;
	color: #fff;
	position: relative;
	z-index: 10;
}

.navbar-nav {
	.fa { display: none; }
	.caret { 
		display: none; 
		color: #fff;
		border: none;
		height: auto;
		width: .8em;
		vertical-align: top;
		&:before {
			content: "\f107"; /* fa-angle-down */
			font-family: 'FontAwesome';
		}
	} 
	> li > a {
    	color: #fff;
		background: $brand-primary;
    	font-size: 1.1em;
		line-height: 1em;
		padding: .75em;
		text-align: left;
		font-family: $brand-font;
		font-weight: 600;
		text-decoration: none;
		text-transform: uppercase;
		&:focus, &:hover { 
			color: $brand-secondary !important;
			background: $brand-primary;
			.caret { color: $brand-secondary; }
		}
	}
	> li.current-menu-parent > a { 
		color: tint($brand-primary,60%);
		//&:focus, &:hover { color: #fff; }
	}
	> li > .dropdown-menu { 
		color: $brand-primary;
		border: none;
		font-size: 1em;
		font-weight: normal;
		padding: .6em 0;
		background: #fff;
		border-radius: 0;
		box-shadow: 0 12px 12px rgba(0, 0, 0, 0.176);
	}
	.dropdown-menu > li > a {
		color: $brand-primary;
		font-weight: 200;
		padding: .4em 1em;
		text-decoration: none;
		font-family: $brand-font;
		font-weight: 300;
	}
	.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover, .dropdown-menu a:hover, .dropdown-menu a:focus { 
		color: #fff !important; 
		background: $brand-primary !important; 
	}
	.quick-links-nav {
		> a { 
			color: #fff;
    		background: $brand-secondary;
			cursor: default;
			&:hover { 
				color: #fff;
				background: lighten($brand-secondary,10%);
			}
    		.caret { }
		}
		.dropdown-menu { background: $brand-secondary; right: 0; left: auto; }
	}
}
.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
	border: none;
	color: #fff;
	background: $brand-secondary;
} 
.navbar-right .dropdown-menu { left: 0; right: auto; }

/* Hover DropDown Menus for Desktop */
@media only screen and (min-width: $screen-sm-min) {
	.navbar-nav .dropdown:hover .dropdown-menu {
		display: block;
	}
	.navbar-nav .dropdown-submenu {
		position: relative !important;
	}
	.navbar-nav .dropdown-submenu > .dropdown-menu {
		top: 0 !important;
		left: 100% !important;
		margin-top: -6px !important;
		margin-left: -1px !important;
		border-radius: 0 !important;
	}
	.navbar-nav .dropdown-submenu:hover > .dropdown-menu {
		display: block !important;
	}
	.navbar-nav .dropdown-submen > a:after {
		display: block;
		content: "\f105";
		font-family: 'FontAwesome';
		margin-top: -18px;
		right: 15px;
		position: absolute;
		font-weight: 300;
	}
}


@media only screen and (max-width: $screen-sm-max) {
	#click-menu, #responsive-menu, #mlpm_btn,
	nav .navbar-primary-container.collapse.in { display: block !important; }
	nav .navbar-primary-container.collapse { display: none !important; }
}

#menu #quick_nav { display: block !important; }


/* Responsive Menu Plugin */
#click-menu,
#click-menu.admin-bar-showing ~ .navbar-toggle,
#click-menu2 ~ .navbar-toggle,
#mlpm_btn ~ .navbar-toggle { display: none !important; }


html.touchevents { 
	#click-menu, #responsive-menu, #mlpm_btn { display: block !important; }
	nav .navbar-primary-container.collapse { display: none !important; }
}

#click-menu, #click-menu2, #mlpm_btn, .mlpm_btn {
	margin: 0;
	padding: 10px 14px;
	border-radius: 0;
	border-radius: none;
	border: none;
	background: $brand-tertiary;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 24px;
	line-height: 24px;
	color: $brand-primary !important;
	font-family: $brand-font;
	@include anti-alias;
	&:hover { border: none; color: #fff !important; background: $brand-primary !important; text-decoration: none; }
}
#mlpm_btn .glyphicon-menu-hamburger { font-size: .85em; }
  
#RM3Lines { display: inline-block; }
#click-menu-label { display: inline-block; font-weight: bold; padding-left: 6px; }

#responsive-menu {
	top: 0 !important; left: 0 !important; 
	z-index: 2000;
	#responsive-menu-title, ul#menu-head > li { font-weight: bold; }
	#responsiveSearch { height: auto !important; }
	ul.sub-menu { padding-left: 0; } 
	ul.sub-menu li { font-weight: normal; }
}

/* 
.pageoverlay:before {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: "";
	background: rgba(0,0,0,0);
	z-index: -1;
}

//Disabled due to iOS Touch/Swipe bug exits 
#pushobj.opened .pageoverlay:before {
	background: rgba(0,0,0,.85);
	z-index: 100;
}

#pushobj.opened #header nav.navigation,
#pushobj.opened #click-menu, 
#pushobj.opened #click-menu2, 
#pushobj.opened #mlpm_btn { z-index: -1; }
*/

.mlpm_w h2 { 
	position: relative;
	background: shade($brand-primary,20%);
	color: shade($brand-primary,20%);
	padding: 0; 
	img { width: 100px; padding: .6em; }
	span { padding: .6em; }
}
.mlpm_w ul li a {
	font-family: $brand-font;
	font-weight: normal;
}