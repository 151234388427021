body {
	background: $body-background;
	font-size: 1.6em;
	line-height: 1.45em;
}

/* HEADINGS */
.content-wrap { 
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { margin-top: .3em; }
	h2 + h3 { font-family: $body-font; color: $body-color; font-weight: 300; font-size: 1.4em; margin: 0 0 1em; }
	h3, .h3 { margin: 0 0 .75em; }
}
h3.block-center { margin-right: auto; margin-left: auto; }
/* ---- */


/* LINKS */
a, a:link, a:visited, a:hover, a:focus { transition: $global-transition; }
.content-wrap a { text-decoration: underline; }
.content-inner { 
	/*
	a { 
		color: $brand-secondary; 
		display: inline-block;
		font-weight: bold;
		padding-bottom: .3em;
		margin-bottom: .3em;
		border-bottom: 3px solid rgba($brand-secondary,.3);
		text-decoration: none;
		&:hover { color: $brand-secondary; border-color: $brand-secondary; }
	}
	/*
	a.btn.btn-default { 
		color: $brand-tertiary; 
		&:hover { color: #fff; } 
	}
	.btn-primary, .btn-secondary, .btn-tertiary { color: #fff !important; }
	*/
}
/* ---- */


/* LEAD */
@media (max-width: $screen-sm-max) {
	p.lead { font-size: 1.2em; }
}
@media (max-width: $screen-xs-max) {
	p.lead { font-size: 1.1em; }
}
/* ---- */


/* IMAGES */
main img { max-width: 100%; height: auto; }
table img { width: 100%; }

img.img-card {
	margin: 1em 0;
	box-shadow: 0 0 2em rgba(0,0,0,.2);
}
/* ---- */


/* WELLS */
.well { border-radius: 1em; }
.well.well-quote {
	font-family: $brand-font; 
	border-radius: 0;
	margin-bottom: 1em;
	font-size: 1.2em;
}
.well.well-primary { 
	background: $brand-primary;
	border-color: $brand-primary;
	color: #fff;
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { color: #fff; }
}
.well.well-secondary { 
	background: $brand-secondary;
	border-color: $brand-secondary;
	color: #fff;
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { color: #fff; }
}
.well.well-tertiary { 
	background: $brand-tertiary;
	border-color: $brand-tertiary;
	color: #fff;
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { color: #fff; }
}
.well.well-quaternary { 
	background: $brand-quaternary;
	border-color: $brand-quaternary;
	color: #fff;
	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { color: #fff; }
}

.well.well-quote.well-lg {
	padding: .7em 1em; 
	font-size: 32px;
}
.well.well-quote.well-sm {
	padding: .5em .8em; 
	font-size: 24px;
}
.well.well-quote.well-left {
	text-align: right;
}
.well.well-quote.well-right {
	text-align: right;
}

.well.well-feature {
	display: table;
	font-size: 1.3em;
	line-height: 1.4em;
	padding: 1em 1.5em;
	.floating-left {
		margin: -2.2em 1.5em .5em -2.2em;
		border-radius: 5px;
		box-shadow: 7px 7px 20px rgba(0,0,0,.15);
		float: left;
	}
	.floating-right {
		margin: -2.2em -2.2em .5em 1.5em;
		border-radius: 5px;
		box-shadow: 7px 7px 20px rgba(0,0,0,.15);
		float: right;
	}
}
@media (max-width: $screen-xs-max) {
	.well.well-feature {
		.floating-left, .floating-right { margin: 0 0 .5em; float: none; }
	}
}

@media (max-width: $screen-xs-max) {
	.well.well-quote.well-lg {
		font-size: 26px;
	}
	.well.well-quote.well-sm {
		font-size: 18px;
	}
}
/* ---- */


/* MISC */
.clear { clear: both; }

.float-right { float: right; }
.float-left { float: left; }
.float-none { float: none; }

.width-auto { width: auto; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-sm { font-size: .8em; }
.text-md { font-size: 1.2em; }
.text-lg { font-size: 1.4em; }

.circle { border-radius: 999em; }

.primary-bg { background-color: $brand-primary !important; }
.secondary-bg { background-color: $brand-secondary !important; }
.tertiary-bg { background-color: $brand-tertiary !important; }
.quaternary-bg { background-color: $brand-quaternary !important; }

.primary-light-bg { background-color: tint($brand-primary,40%) !important; }
.secondary-light-bg { background-color: tint($brand-secondary,40%) !important; }
.tertiary-light-bg { background-color: tint($brand-tertiary,40%) !important; }
.quaternary-light-bg { background-color: tint($brand-quaternary,40%) !important; }

.primary-dark-bg { background-color: shade($brand-primary, 20%) !important; }
.secondary-dark-bg { background-color: shade($brand-secondary, 20%) !important; }
.tertiary-dark-bg { background-color: shade($brand-tertiary, 20%) !important; }
.quaternary-dark-bg { background-color: shade($brand-quaternary, 20%) !important; }

li.draft { display: none; }

ul { list-style-type: disc; }
ul.list-style-none { padding: 0; li { list-style-type: none; } }

.pad-top { padding-top: .5em; }
.pad-bottom { padding-bottom: .5em; }

.margin-top { margin-top: .5em; }
.margin-bottom { margin-bottom: .5em; }
.margin-center { margin-right: auto; margin-left: auto; }

.desaturate { @include desaturate; }
.anti-alias { @include anti-alias; }

p.leaddropcap:first-letter {
	float: left;
	font-size: 2.85em;
	line-height: .75em;
	padding-top: .1em;
	padding-right: .05em;
}
/* ---- */


/* ICONS */
.icon-round, .glyphicon.round { 
	height: 2em;
	width: 2em;
	border-radius: 10em;
	padding: 0.5em 0;
	text-align: center;
	display: inline-block; 
	margin-right: .5em;
}
.icon-rounded, .glyphicon.rounded { 
	height: 2em;
	width: 2em;
	border-radius: .3em;
	padding: 0.55em .5em .5em .6em;
	text-align: center;
	display: inline-block; 
	margin-right: .5em;
}
.icon-square, .glyphicon.square {
	height: 2em;
	width: 2em;
	padding: 0.5em 0;
	text-align: center;
	display: inline-block; 
	margin-right: .5em;
}
.icon-xs, .glyphicon.xs { 
	font-size: .6em; 
	line-height: 1em;
}
.icon-sm, .glyphicon.sm { 
	font-size: 1.2em; 
	line-height: 1.2em;
}
.icon-md, .glyphicon.md { 
	font-size: 1.4em; 
	line-height: 1.4em;
}
.icon-lg, .glyphicon.lg { 
	font-size: 1.6em;
	line-height: 1.6em;
}
.icon-xl, .glyphicon.xl { 
	font-size: 1.8em;
	line-height: 1.8em;
}

.icon-primary, .glyphicon.primary { color: $brand-primary; } 
a:hover .icon-primary, a:hover .glyphicon.primary { color: $brand-secondary; }
.icon-secondary, .glyphicon.secondary { color: $brand-secondary; } 
a:hover .icon-secondary, a:hover .glyphicon.secondary { color: #fff; }
.icon-tertiary, .glyphicon.tertiary { color: $brand-tertiary; } 
a:hover .icon-tertiary, a:hover .glyphicon.tertiary { color: $brand-secondary; }
.icon-white, .glyphicon.white { color: $brand-primary; background: #fff; } 
a:hover .icon-white, a:hover .glyphicon.white { color: $brand-secondary; }

.icon-primary.icon-round, 
.icon-primary.icon-rounded,
.icon-primary.icon-square,
.glyphicon.primary.round,
.glyphicon.primary.rounded,
.glyphicon.primary.square { background: $brand-primary; color: #fff; }
.icon-secondary.icon-round, 
.icon-secondary.icon-rounded,
.icon-secondary.icon-square,
.glyphicon.secondary.round,
.glyphicon.secondary.rounded,
.glyphicon.secondary.square { background: $brand-secondary; color: #fff; }
.icon-tertiary.icon-round, 
.icon-tertiary.icon-rounded,
.icon-tertiary.icon-square,
.glyphicon.tertiary.round,
.glyphicon.tertiary.rounded,
.glyphicon.tertiary.square { background: $brand-tertiary; color: #fff; }

.-indentspan span { float: left; display: inline-block; text-indent: -9999em; display: none; }

/* SOCIAL COLORS */
$twitter: #00aced; 
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #bc2a8d;
$flickr: #ff0084;
$dribble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$wordpress: #21759b;
$stumbleupon: #eb4823;
$snapchat: #fffc00;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;
$spotify: #00e461;
$whatsapp: #4dc247;
$rss: #ff6600;
$compass: #0067b6;

@mixin social-icon($social) {
	color: $social; &:hover, &:focus { color: lighten($social,10%) !important; }
}

@mixin social-icon-bg($social) {
	color: #fff; background-color: $social !important; &:hover, &:focus { color: #fff !important; background-color: lighten($social,10%) !important; }
}

.twitter { @include social-icon($twitter); }
.facebook { @include social-icon($facebook); }
.googleplus { @include social-icon($googleplus); }
.pinterest { @include social-icon($pinterest); }
.linkedin { @include social-icon($linkedin); }
.youtube { @include social-icon($youtube); }
.vimeo { @include social-icon($vimeo); }
.tumblr { @include social-icon($tumblr); }
.instagram { @include social-icon($instagram); }
.flickr { @include social-icon($flickr); }
.dribble { @include social-icon($dribble); }
.foursquare { @include social-icon($foursquare); }
.wordpress { @include social-icon($wordpress); }
.snapchat { @include social-icon($snapchat); }
.stumpleupon { @include social-icon($stumbleupon); }
.yahoo { @include social-icon($yahoo); }
.blogger { @include social-icon($blogger); }
.soundcloud { @include social-icon($soundcloud); }
.whatsapp { @include social-icon($whatsapp); }
.rss { @include social-icon($rss); }
.compass { @include social-icon($compass); }

.social-icons-bg .social-icons {
	.twitter { @include social-icon-bg($twitter); }
	.facebook { @include social-icon-bg($facebook); }
	.googleplus { @include social-icon-bg($googleplus); }
	.pinterest { @include social-icon-bg($pinterest); }
	.linkedin { @include social-icon-bg($linkedin); }
	.youtube { @include social-icon-bg($youtube); }
	.vimeo { @include social-icon-bg($vimeo); }
	.tumblr { @include social-icon-bg($tumblr); }
	.instagram { @include social-icon-bg($instagram); }
	.flickr { @include social-icon-bg($flickr); }
	.dribble { @include social-icon-bg($dribble); }
	.foursquare { @include social-icon-bg($foursquare); }
	.wordpress { @include social-icon-bg($wordpress); }
	.snapchat { @include social-icon-bg($snapchat); }
	.stumpleupon { @include social-icon-bg($stumbleupon); }
	.yahoo { @include social-icon-bg($yahoo); }
	.blogger { @include social-icon-bg($blogger); }
	.soundcloud { @include social-icon-bg($soundcloud); }
	.whatsapp { @include social-icon-bg($whatsapp); }
	.rss { @include social-icon-bg($rss); }
	.compass { @include social-icon-bg($compass); }
	
	a { color: #fff !important; }
}
.social-icons span { padding-left: .25em; }
/* ---- */


/* TABLES */
table { table-layout: fixed; }
table thead, table.thead > tbody > tr:first-child { background: $brand-primary; color: #fff; font-size: 1.1em; }
.table-striped > tbody > tr:nth-of-type(2n+1) { background: rgba( $brand-primary, .05 ); }
.table-hover > tbody > tr:hover { background: rgba( $brand-primary, .1 ); }
.table-bordered > tbody > tr > td, 
.table-bordered > tbody > tr > th, 
.table-bordered > tfoot > tr > td, 
.table-bordered > tfoot > tr > th, 
.table-bordered > thead > tr > td, 
.table-bordered > thead > tr > th 
.table > tbody > tr > td, 
.table > tbody > tr > th, 
.table > tfoot > tr > td, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > thead > tr > th { border-color: rgba( $brand-primary, .1 ); }

.table-borderless > tbody > tr > td, 
.table-borderless > tbody > tr > th, 
.table-borderless > tfoot > tr > td, 
.table-borderless > tfoot > tr > th, 
.table-borderless > thead > tr > td, 
.table-borderless > thead > tr > th { border: none; }

table.lg-padd td { padding: 22px !important; }
table.md-padd td { padding: 11px !important; }

@-moz-document url-prefix() {
 fieldset { display: table-cell; }
}
/* ---- */


/* MODALS */
$modal-backdrop: shade($brand-quaternary,30%);
.modal { 
	top: 5vh;
	button.close {
		opacity: 1;
		background: #444;
		border: .08em solid #fff;
		border-radius: 999em;
		box-shadow: 0 0 5px rgba(0,0,0,.3);
		color: #fff;
		font-size: 2em;
		line-height: 0;
		height: 1.2em;
		width: 1.2em;
		position: absolute;
		top: -.35em;
		right: -.35em;
		font-weight: normal;
		&:hover, &:focus {
			opacity: 1;
			background: #fff;
			color: #444;
			border-color: #444;
		}
		span { display: block; margin: -.1em 0 0; }
	}
	.modal-header { padding-left: 25px; padding-right: 25px; }
	.modal-body { padding: 25px; }
	.modal-dialog { 
		margin-left: auto; 
		margin-right: auto; 
		width: 90%; 
		max-width: 800px;
	}
}
.modal-backdrop { background: $modal-backdrop; }
.modal-backdrop.in { opacity: .95; }

/* Invisible Modal Box */
.modal-glass {
	.modal-dialog {
		color: #fff;
		width: 100%;
		max-width: 1000px;
		font-size: 1.2em;
	}
	.modal-content {
		background: none;
		box-shadow: none;
		border: none;
	}
	.modal-header {
		border-bottom: 1px solid tint($modal-backdrop,40%);
	}
	.modal-title {
		font-size: 2em;
		color: #fff;
	}
	button.close {
		background: $modal-backdrop;
		font-size: 1.75em;
	}
	
	/* Gravity Form styles */
	.gform_wrapper {
		font-family: $brand-font;
		.gfield_required, 
		fieldset.gfieldset legend.gfield_label { color: #fff; }
		fieldset.gfieldset legend.gfield_label { margin-bottom: 0; border-bottom: 0; }
	}
	
	.search-form {
		position: relative;
		> label, input.search-field { width: 100%; height: 44px; font-size: 1em; }
		.search-submit { 
			margin: 0; 
			padding-top: 6px; 
			padding-bottom: 6px; 
			position: absolute; 
			right: 0; 
			top: 0;
			border-radius: 0 3px 3px 0;
			height: 44px;
		}
	}
	
}

.modal-video {
	.modal-dialog {
		max-width: 1600px;
	}
}

.modal-large {
	.modal-dialog {
		max-width: 1200px;
		width: 80%;
	}
}
/* ---- */


/* SPINNER */
.page-loader { 
	width: 100%;
	position: fixed;
	top: 48%;
	z-index: 0; 
}
.spinner {
	width: 150px;
	height: 150px;
	position: relative;
	margin: 0 auto;
	img { max-width: 150px; }
	
	.shine {
		width: 1000px;
		height: 100px;
		margin-left: -100px;
		transform: rotate(30deg);
		background: -webkit-linear-gradient(top, transparent, rgba(255,255,255,0.25),transparent);
		position: absolute;
		-webkit-animation: shine 4s ease-in-out infinite;
	}
	@-webkit-keyframes shine{
		0%,100% {
			margin-top: -100px;
		}
		50% {
			margin-top: 800px;
		}
	}
}

/* Target - Safari 7.1+ (9.0 is the latest version of Safari at this time) */
/* Hide Spinner for Safari due to lack of native support for js 'beforeunload',
results in janky flash of spinner, best to jun hide the spinner */
_::-webkit-full-page-media, _:future, :root {
  .page-loader { display: none; }
}
/* ---- */


/* IMAGE ANIMATIONS */
@-webkit-keyframes zoomimagein {
	0% { 
		transform: scale(1.2,1.2); 
		-webkit-transform: scale(1.2,1.2); 
		filter: blur(15px);
		-webkit-filter: blur(15px);
	}
	100% { 
		transform: scale(1.2,1.2); 
		-webkit-transform: scale(1,1); 
		filter: blur(0);
		-webkit-filter: blur(0);
	}
}
@keyframes zoomimagein {
	0% { 
		transform: scale(1.2,1.2); 
		-webkit-transform: scale(1.2,1.2); 
		filter: blur(15px);
		-webkit-filter: blur(15px);
	}
	100% { 
		transform: scale(1.2,1.2); 
		-webkit-transform: scale(1,1); 
		filter: blur(0);
		-webkit-filter: blur(0);
	}
}
@mixin image-animated {
	transition: $global-transition;
	-webkit-animation: zoomimagein 5s;
	animation: zoomimagein 5s;
}
@media (min-width: $screen-md-min) {
	.image-animated {
		@include image-animated;
	}
}
/* ---- */


/* MEDIA QUERIES */
@media only screen and (min-width: $screen-xl-min) {
	body { font-size: 160%; }
	.container { width: $screen-xl-container; max-width: 1400px; }
	/*
	h1 { font-size: 2em; }
	h2 { font-size: 1.8em; }
	h3 { font-size: 1.6em; }
	h4 { font-size: 1.4em; }
	h5 { font-size: 1.2em; }
	h6 { font-size: 1em; }
	*/
}
@media (max-width: $screen-md-min) {
	.content-wrap { font-size: 1.05em; line-height: 1.5em; }
}
@media (max-width: $screen-lg-min) {

}
/* ---- */