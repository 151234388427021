/* TIMELY Calendar Widget */
.timely {
	font-family: $brand-font;
	
	a { color: $brand-primary; }
	a.ai1ec-btn {
		border-radius: 0;
		color: #fff;
		text-align: center !important;
		background: $brand-tertiary;
		border: none;
		transition: $global-transition;
		&:hover {
			color: #fff;
			background: $brand-primary;
		}
	}
}
.ai1ec-main-container { padding-bottom: 1.5em; }
.ai1ec-calendar-toolbar { border-style: dashed; display: none; }
.ai1ec-title-buttons .ai1ec-btn-group { margin-left: 0; }

.ai1ec-btn,
.ai1ec-btn-xs {
    padding: 8px 12px !important;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
	border-radius: 3px;
}
.ai1ec-views-dropdown {
	margin-top: 5px; 
	i { font-size: 1.4em; line-height: 11px; }
}
.ai1ec-dropdown.ai1ec-btn { 
	background: $brand-tertiary; 
	border: none; 
	color: #fff; 
	border-radius: 0; 
	&:hover { background: $brand-primary; color: #fff; }
	.ai1ec-dropdown-toggle { padding: 0; }
}
ul.ai1ec-dropdown-menu {
	a, 
	> div > a, 
	> div > a:visited { 
		color: $brand-tertiary; 
		padding: 8px 25px 8px 15px; 
		border: none; 
	}
	> div.ai1ec-active > a { background: $brand-secondary; color: #fff; }
	> li > a { padding: .25em .5em !important; }
}


.widget.widget_ai1ec_agenda_widget {
	margin-bottom: 1em;
	padding: 0;
	color: #494949;
	margin-bottom: 1.5em;
	> h4 { 
		margin: 0;
	}
	.ai1ec-subscribe-buttons-widget {
		clear: both;
		border-top: 2px solid rgba($brand-secondary,.4);
		background: rgba($brand-secondary,.2);
		a.ai1ec-btn, .ai1ec-dropdown.ai1ec-btn {
			border: none;
			background: none;
			color: $brand-secondary !important;
			font-weight: bold;
			&:hover { color: $brand-primary !important; }
		}
	}
}



.ai1ec-agenda-view .ai1ec-date-title:hover { background: $brand-secondary; }
.ai1ec-agenda-view .ai1ec-event, 
.ai1ec-agenda-widget-view .ai1ec-event { 
	margin: 0;
	border-radius: 0;
	border: none;
}

.page-content .ai1ec-agenda-widget-view .ai1ec-event > a,
.page-content .ai1ec-popover.popup {
	background-color: #fff;
}
.ai1ec-btn-group a:visited {
    color: #f6f56a;
}
.ai1ec-allday-badge { 
	background: $brand-quaternary; 
	font-weight: bold;
	padding: .1em .8em;
	text-transform: uppercase;
	font-size: .8em;
}


/* Widget View */
.ai1ec-agenda-widget-view {
	max-width: 100%;
	.ai1ec-date { 
		margin: 0;
		padding: .75em 1.5em;
		font-family: $brand-font;
		background: none;
		border: none;
		border-bottom: 1px solid rgba($brand-quaternary,.15);
		position: relative;
		&.ai1ec-today { 
			border-top: 0;
			background: tint($brand-primary,90%);
		}
	}
	.ai1ec-date-title { 
		margin: 0;
		padding: 0;
		position: absolute;
		right: 1.5em;
		width: auto;
		color: $brand-secondary;
		float: none;
		background: none;
		border: none;
		text-shadow: none;
		border-radius: 0;
		&:hover { background: none; }
	}
	.ai1ec-month, .ai1ec-day, .ai1ec-weekday {
		padding: 0;
		color: $brand-quaternary;
		font-size: 1em;
		font-weight: normal;
		display: inline-block;
		background: none;
		text-shadow: none;
		letter-spacing: 0;
		border-radius: 0;
	}
	.ai1ec-month {}
	.ai1ec-weekday {}
	.ai1ec-date-events { margin: 0; }
	.ai1ec-event {
		.ai1ec-event-title {
			display: block;
			font-size: 1.2em;
			font-weight: bold;
			color: tint($brand-primary,20%);
		}
		.ai1ec-event-time { 
			color: $brand-quaternary;
			font-weight: normal;
		}
		> a {
			padding: 0;
			background: none;
			border: none;
			color: $brand-secondary;
			&:hover {
				color: $brand-secondary;
				border: none !important;
				border: 1px solid rgba(255,255,255,.6);
			}
		}
	}
	.ai1ec-allday-badge {
		display: table;
	}
}
/* -- */


/* Calendar View */
.ai1ec-agenda-view {
	.ai1ec-date { 
		margin: 0 0 10px;
		border: none;
		background: tint($brand-primary,40%); 
		transition: $global-transition;
		&:hover {
			background: $brand-secondary;
			.ai1ec-date-title { background: $brand-secondary; }
		}
		&.ai1ec-today { 
			border-top: 0;
			background: $brand-primary;
		}
	}
	.ai1ec-date-title {
		margin: 6.5px .75em 6.5px 0;
		border: none;
		width: 6.75em;
		background: tint($brand-primary,40%);
		color: #fff !important;
		text-shadow: none;
		padding: 0;
		margin: 0;
		border-radius: 0;
		&:hover { color: #fff !important; }
	}
	.ai1ec-day { 
		padding: 0.35em 0.5em 0.65em;
		font-weight: 600;
		font-size: 3em;
	}
	.ai1ec-weekday { display: none; }
	.ai1ec-today {
		.ai1ec-date { background: $brand-primary; }
		.ai1ec-date-title { background: $brand-primary; }
		.ai1ec-date-events { }
	}
	.ai1ec-month {
		background: none;
		color: #fff;
		text-shadow: none;
		letter-spacing: 0em;
		padding: .7em 0 .25em;
		border-radius: 0;
		font-size: 1.6em;
		font-weight: 300;
		font-family: $brand-font;
	}
	.ai1ec-event {
		background: none;
		padding: 1.5em; 
		&.ai1ec-expanded { border-color: #e5e5e5; }
		.ai1ec-event-title {
			color: $brand-primary;
			font-size: 1.4em;
		}
		.ai1ec-event-time {
			font-size: 1em;
		}
	}
	.ai1ec-date-events { 
		margin: 0; 
		border: 1px solid #e5e5e5;
		background: #fff;
		&:hover .ai1ec-event-title { color: $brand-primary; }
	}
}
/* -- */


.timely .ai1ec-btn-default.ai1ec-disabled, .timely .ai1ec-btn-default.ai1ec-disabled:hover, .timely .ai1ec-btn-default.ai1ec-disabled:focus, .timely .ai1ec-btn-default.ai1ec-disabled:active, .timely .ai1ec-btn-default.ai1ec-disabled.ai1ec-active, .timely .ai1ec-btn-default[disabled], .timely .ai1ec-btn-default[disabled]:hover, .timely .ai1ec-btn-default[disabled]:focus, .timely .ai1ec-btn-default[disabled]:active, .timely .ai1ec-btn-default.ai1ec-active[disabled], fieldset[disabled] .timely .ai1ec-btn-default, fieldset[disabled] .timely .ai1ec-btn-default:hover, fieldset[disabled] .timely .ai1ec-btn-default:focus, fieldset[disabled] .timely .ai1ec-btn-default:active, fieldset[disabled] .timely .ai1ec-btn-default.ai1ec-active {
	background: $brand-tertiary;
}

.base-column-content .timely.ai1ec-agenda-widget-view {
	.ai1ec-subscribe-dropdown { 
		min-height: auto; 
	}
	> p { margin: 0; }
}


/* TIMELY Calendar Page */
.ai1ec_event {
	time.updated, .byline {
    	display: inline-block;
    	color: #999;
    	a { color: #999; }
	}
	.byline:before { content: " - "; }
	.timely.ai1ec-single-event { 
    	margin-top: 3em;
		.ai1ec-field-label { width: auto; }
		.ai1ec-btn { color: #fff !important; }
		.ai1ec-btn:hover {
			color: #fff !important; 
			background: $brand-primary;
		}
	}
}