/* Accessibility Widgets */
.accessibility-widget {
	position: relative;
	float: right;
	color: rgba(255,255,255,.75);
	.accessibility-options {
		margin: 0;
		padding: 0;
		li { 
			padding: 0 .1em;
			list-style-type: none; 
			display: inline-block; 
			float: left;
		}
		li.pad-right { padding-right: .6em; }
		a { 
			display: block;
			line-height: 1em;
			padding: .2em .3em; 
			cursor: pointer; 
			color: rgba(255,255,255,.75); 
			position: relative;
			z-index: 10000;
			&:hover { color: #fff; } 
		}	
	}
	
	.speechSythesis-dropdown { 
		display: none; // Hidden by Default
		top: 2em;
		right: 0;
		left: auto;
		font-size: .9em;
		color: $body-color;
		form {
			div { padding-bottom: .5em; }
			label { display: block; }
			input[type="range"] { width: 80%; display: inline-block; float: left; padding-top: .2em; }
			.value { width: 20%; display: inline-block; float: left; text-align: right; }
			.controls { 
				margin-top: .75em; 
				.btn { margin-bottom: 0; }
			}
		}
	}
	
}

.dropdown-modal {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	width: 300px;
	background: #fff;
	box-shadow: 0 4px 15px rgba(0,0,0,.1);
	.close { padding: .25em .5em; font-size: 1.8em; cursor: pointer; }
	.dropdown-modal-content { padding: 2em 1.5em 1.5em; }
}