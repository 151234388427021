.search-submit:hover {
	border-color: $brand-tertiary;
}

.searchwp-live-search-result {
	font-family: $brand-font;
	font-weight: bold;
	font-size: 1.1em;
	@include anti-alias;
	a { color: $brand-primary; &:hover { color: $brand-secondary;} }
}