/* Default News Items */
.news-items { padding-bottom: 1em; }
.readmore { display: block; }

article {
	header { 
		margin: 0 0 20px; 
		.entry-title a { text-decoration: none;	}
	}
	.entry-meta {
		font-size: .8em;
		font-family: $brand-font;
		color: rgba($brand-quaternary,.5);
		a { color: rgba($brand-quaternary,.5); &:hover { color: rgba($brand-quaternary,.75); } }
		time { }
		.readtime { padding-left: 1em; }
  		.entry-category { float: right; padding-left: 1em; }
  		.author { display: none; padding-left: 1em; }
		.glyphicon { font-size: .9em; padding-right: .2em; }
	}
	
	.news-item.single-post { margin-bottom: 3em; }
}

/* Single Post Page */
body.single {
	.single-header { 
		margin-top: 2em; 
		//text-align: center;
		img { margin: 0 auto; max-width: 100%; height: auto; }
		.page-header { margin-top: 6em; }
		img + div .page-header { margin-top: 2em; }
	}
	#block { 
		margin-top: 0; padding-top: 0;
		.entry-meta { 
			//text-align: center; 
		}
		.entry-category { float: none; }
		.entry-content hr { margin: 3% 0; }
	}
}
@media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
	body.single .single-header { 
		.page-header { margin-top: 3em; }
	}
}
@media (max-width: $screen-xs-max) {
	body.single-post {
		.single-header { margin: 0; }
		.page-header { font-size: .75em; }
		#block {
			padding-top: 0;
		}
	}
}


/* News List */
article .news-item { 
	margin-bottom: 2em;
	color: $body-color;
	
	.news-thumb {
		img { height: auto; width: 100%; }
	}
	.news-header { }
	.entry-meta { 
		font-family: $brand-font;
		background: $brand-secondary;
		padding: .5em 1em;
		color: #fff;
		a { color: #fff; &:hover { color: #fff; } }
	}
	.entry-title {
		margin: 0.75em 0 0.25em;
		padding: 0;
		color: $brand-primary;
		font-family: $brand-font;
		font-size: 1.2em;
		font-weight: 600;
		a {
			color: $brand-primary;
			text-decoration: none;
			&:hover {
				color: $brand-secondary;
			}
		}
	}
	.news-body { }
	.news-caption {
		padding: 0;
		.btn {
			position: absolute;
			bottom: 15px;
			right: 15px;
			margin: 0;
		}
	}
	.readmore { display: inline-block; }
}

#news-dash article .news-item  {
	
}

body.search-results article {
	border-bottom: 1px solid rgba($brand-tertiary,.15);
	padding-bottom: .5em;
	margin-bottom: 1em;
	header {
		margin-bottom: .5em;
		.entry-title a { 
			color: $brand-secondary; 
			text-decoration: none;	
			&:hover { color: lighten($brand-secondary,15%); }
		}
	}
}
/* -- */


/* Featured News */
.featured-news {
	article .news-item {
		box-shadow: 0 0 10px rgba(0,0,0,.1);
		.news-thumb-wrapper { position: relative; }
		.news-thumb { 
			overflow: hidden; 
			border-bottom: 4px solid $brand-secondary;
			&:hover .news-thumbnail {
				-ms-transform: scale(1.05,1.05);
				-webkit-transform: scale(1.05,1.05);
				-moz-transform: scale(1.05,1.05);
				transform: scale(1.05,1.05);
			}
			.news-thumbnail { transition: $global-transition; }
		}
		.entry-meta {
			padding: .2em .5em;
			position: absolute;
			top: 15px;
			left: -10px;
			font-family: $brand-font;
			font-weight: 600;
			.glyphicon { color: #fff; } 
			.entry-category { display: none; }
		}
		.news-header { 
		}
		.entry-title { 
			color: #231f20;
			margin: 1em 0 0.25em;
			font-size: 1.8em;
			font-family: $body-font;
		}
		.news-body { padding: 0 1em .5em; }
		.news-caption {
			padding: 0;
		}
	}
}

@media (max-width: $screen-xs-max) {
	article .news-item { margin-bottom: 1em; }
	.featured-news {
		article .news-item {
			.entry-title { font-size: 2em; }
		}
	}
}

body.page-template-template-home {
	article .news-item	.entry-meta .entry-category { display: none; } 
}
/* -- */


/* RSS Feed / Feedzy */
.feedzy-rss {
  li.rss_item { 
     padding-bottom: 5px !important;
    .title { font-size: 1.4em; }
    .rss_content small { color: #666 !important; }
  }
  li.rss_item:last-child { border-bottom: none !important; }
}
/* -- */