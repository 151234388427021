.newsletter-widget {
	li { 
		list-style-type: none; 
		padding: 0; 
		margin: 0 0 .7em; 
		transition: $global-transition;
		&:hover .fa { 
			background: $brand-primary; 
		}
	}
	.fa { 
		font-size: 1.4em; 
		padding: 15px;
		color: #fff;
		width: 15%;
		text-align: center;
		background: $brand-tertiary; 
		border-radius: 999em;
	}
	.newsletter { 
		width: 85%; 
		float: right; 
		padding: 5px 15px; 
		color: $body-color; 
		font-size: .9em; 
		> div { font-weight: bold; color: $brand-primary; }
	}
}

#publications-view {
	.tab-content {
		padding: 1.5em;
		background: none;
	}
	.newsletter { 
		margin-top: 2.5em;
		padding: 1em;
		font-size: .9em;
		color: #fff;
		background: tint($brand-tertiary,90%);
		text-decoration: none;
		display: block;
		transition: $global-transition;
		&:hover { background: tint($brand-tertiary,80%); }
		
		.name, .category, .date { display: block; }
		.thumb { 
			margin: -2.5em 7% 0 0;
			text-align: center;
			width: 33%;
			float: left;
			background: #fff;
			box-shadow: 0 0 10px rgba(0,0,0,.1);
		}
		.title {
			width: 60%;
			float: left;
			font-family: $brand-font; 
			line-height: 1.3em; 
			.name { 
				font-size: 1.4em;
				line-height: 1.2em;
				color: $brand-primary; 
				font-weight: bold;
			}
			.date { color: $brand-quaternary; padding-top: .5em; }
			.category { color: $brand-quaternary;  }
		}
	}
}