/* CUSTOM SASS FUNCTIONS */

// Return a lighter color
// 0% is the same color, 100% is white
@function tint($color, $percent: 0%, $alpha: 1) {
   @return rgba(mix(white, $color, $percent), $alpha);
}

// Return a darker color
// 0% is the same color, 100% is black
@function shade($color, $percent: 0%, $alpha: 1) {
    @return rgba(mix(black, $color, $percent), $alpha);
}

// Return an intensity of white
// 0% is black, 50% is a middle gray, 100% is white
@function white($percent: 100%, $alpha: 1) {
    @return rgba(mix(white, black, $percent), $alpha);
}

// Return an intensity of black
// 0% is white, 50% is a middle gray, 100% is black
@function black($percent: 100%, $alpha: 1) {
    @return rgba(mix(black, white, $percent), $alpha);
}

/* ---- */


/* CUSTOM VARIABLES */

/* Glyphicons font path */
$icon-font-path: "../fonts/";

/* Grid settings */
$main-sm-columns: 12;
$sidebar-sm-columns: 3;

/* Colors */
$brand-primary: #86134f;
$brand-secondary: #b79147;
$brand-tertiary: tint($brand-secondary,40%);
$brand-quaternary: #545962;
$body-color: #505154;
$body-background: #fff;

/* Fonts */
$brand-font: 'Roboto Condensed', 'Opens Sans', Helvetica, Arial, sans-serif;
$body-font: 'Roboto', 'Opens Sans', Helvetica, Arial, sans-serif;

/* Transitions */
$global-transition: all 500ms cubic-bezier(0.770, 0.000, 0.175, 1.000);

/* XL-Screen Support */
$screen-xl-min: 1800px;
$screen-xl-max: 2399px;
$screen-xl-container: 65vw;

$screen-xxl-min: 2400px;
$screen-xxl-max: 2999px;
$screen-xxl-container: 65vw;

/* ---- */


/* MIXINS */
@mixin desaturate {
	filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");/* Firefox 10+, Firefox on Android */
	filter: gray; /* IE6-9 */
	-webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}
@mixin anti-alias {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@mixin gradient-bottom-top($color1,$alpha1,$position1,$color2,$alpha2,$position2) {
	background: $color1; /* Old browsers */
	background: -moz-linear-gradient(top, rgba($color1,$alpha1) $position1, rgba($color2,$alpha2) $position2); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba($color1,$alpha1) $position1,rgba($color2,$alpha2) $position2); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba($color1,$alpha1) $position1,rgba($color2,$alpha2) $position2); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=0 ); /* IE6-9 */
}
@mixin gradient-left-right($color1,$alpha1,$position1,$color2,$alpha2,$position2) {
	background: $color1; /* Old browsers */
	background: -moz-linear-gradient(left, rgba($color1,$alpha1) $position1, rgba($color2,$alpha2) $position2); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, rgba($color1,$alpha1) $position1,rgba($color2,$alpha2) $position2); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, rgba($color1,$alpha1) $position1,rgba($color2,$alpha2) $position2); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 ); /* IE6-9 */
}
@mixin gradient-lower-diagonal($color1,$alpha1,$position1,$color2,$alpha2,$position2) {
	background: $color1; /* Old browsers */
	background: -moz-linear-gradient(-45deg, rgba($color1,$alpha1) $position1, rgba($color2,$alpha2) $position2); /* FF3.6-15 */
	background: -webkit-linear-gradient(-45deg, rgba($color1,$alpha1) $position1,rgba($color2,$alpha2) $position2); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(135deg, rgba($color1,$alpha1) $position1,rgba($color2,$alpha2) $position2); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 ); /* IE6-9 */
}
@mixin gradient-upper-diagonal($color1,$alpha1,$position1,$color2,$alpha2,$position2) {
	background: $color1; /* Old browsers */
	background: -moz-linear-gradient(45deg, rgba($color1,$alpha1) $position1, rgba($color2,$alpha2) $position2); /* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, rgba($color1,$alpha1) $position1,rgba($color2,$alpha2) $position2); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, rgba($color1,$alpha1) $position1,rgba($color2,$alpha2) $position2); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color1', endColorstr='$color2',GradientType=1 ); /* IE6-9 */
}
@mixin fa {
	font-family: 'FontAwesome';
	display: inline-block;
	font-weight: normal;
}
/* ---- */