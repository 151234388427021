/* ------------------------------------------
/ MULTI-LEVEL PUSH MENU
/ Requires use of the JS assets: 
/ jquery.mlpm.min.js, jquery.mlpm.custom.js
------------------------------------------- */

/* Core Menu Styling */
$mlpm-padding: .6em;

.mlpm_w {
	position: absolute;
	overflow: hidden;
	min-width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	color: #fff;
	
	.levelHolderClass {
		position: absolute;
		overflow: hidden;
		top: 0;
		background: $brand-primary;
		width: auto;
		min-height: 100%;
		font-size: 1em;
		zoom: 1;
		z-index: 10;
	}
	.ltr {
		margin-left: -100%;
		left: 0;
		box-shadow: 5px 0 5px -5px rgba(0,0,0,.3);
		filter: progid:DXImageTransform.Microsoft.Shadow(color=#000000,direction=90,strength=2);
	}
	.rtl {
		margin-right: -100%;
		right: 0;
		box-shadow: 5px 0 5px 5px rgba(0,0,0,.3);
		filter: progid:DXImageTransform.Microsoft.Shadow(color=#000000,direction=270,strength=2);
	}
	.mlpm_inactive {
		background: $brand-primary; /* rgba(255,255,255,.1); */
		&:before {
			background: rgba($brand-primary,.75);
			content: "";
			height: 100%;
			position: absolute;
			width: 100%;
		}
	}
	h2 {
		font-size: 1.5em;
		line-height: 1.2em;
		color: #fff;
		margin: 0;
		padding: $mlpm-padding;
		background: rgba(0,0,0,.3);
		cursor: pointer;
		text-align: left !important;
		> span { color: #fff; }
		.glyphicon-menu-hamburger {
			top: 7px;
			float: right;
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		width: 100% !important;
		visibility: visible !important;
		li {
			cursor: pointer;
			border-top: 1px solid rgba(255,255,255,.1);
			border-bottom: 1px solid rgba(0,0,0,.1);
			padding: $mlpm-padding;
			transition: $global-transition;
			&:last-child { }
			&:hover {
				transition: $global-transition;
				background-color: $brand-secondary;
			}
			a {
				display: block;
				outline: none;
				overflow: hidden;
				font-size: 1.1em;
				line-height: 1.4em;
				font-weight: bold;
				padding: 0 ($mlpm-padding / 2);
				text-decoration: none;
				color: #fff;
				background: none !important;
				text-align: left;
				white-space: normal !important;
				&:focus, &:hover { color: #fff; background: none !important; }
			}
		}
	}
	.backItemClass {
		display: block;
		padding: $mlpm-padding;
		background: rgba(0,0,0,.3);
		border-top: 1px solid rgba(0,0,0,.1);
	}
	.floatRight { float: right; }
	.floatLeft { float: left; }
	.cursorPointer { cursor: pointer; }
	.iconSpacing_ltr { padding: 0 $mlpm-padding 0 0; }
	.iconSpacing_rtl { padding: 0 0 0 $mlpm-padding; }
	
	/* Override Bootstrap */
	.dropdown, .dropup { position: inherit; }
	.dropdown-menu {
		display: block;
		border: none;
		position: relative;
		width: 100%;
		float: none;
		font-size: inherit;
		border-radius: none;
		background: none;
		box-shadow: none;
		text-align: inherit;
	}
}
/* -- */


/* Full Expand/Collapse */
#menu {
	position: absolute;
	right: 0;
	top: 0;
	min-height: 100%;
	z-index: 1000;
	background: #333;
	display: none;
	/* jQuery sets to display:block on DocumentReady */
}
#menu, .mlpm_w, .mlpm_w > div { }
#pushobj {
	position: relative;
}
body.admin-bar #menu { top: 28px; }


/* Disabled Top Item Sub nav Titles in Desktop Hover Menu */
/* Disabled Desktop Menu Icons */
.navbar-primary-container .mlpm, .navbar-primary-container .icon { display: none; }

/* Custom Styles */
li.mlpm { font-size: 1.4em; background: $brand-tertiary; /*rgba(255,255,255,.3);*/ }

.mlpm_w { 

	/* Reverse Nav Icon Positions
	.floatRight {
		float: left; padding: 0; 
	}
	.floatLeft, .cursorPointer, .backItemClass .floatRight {
		float: right; padding: 0; 
	}
	*/
	.caret { display: none; }
	span.icon, .fa { margin-right: 5px; font-size: 1.2em; width: 25px; text-align: center; }
	.icon-circle-left, .icon-circle-right { color: rgba(0,0,0,.5); }
	li.search {
		padding-top: 1em;
		padding-bottom: 1em;
		&:hover { background: none; }
	}
}

#mlpm-searchFormContainer {
	#searchField {
		width: 235px;
		float: left;
		border-radius: 4px 0 0 4px;
		height: 2.6em;
	}
	.searchsubmit {
		float: left;
		background: lighten($brand-tertiary, 25%);
		border: none;
		color: $brand-primary;
		font-size: .82em;
		margin-bottom: 2px;
		padding: .6em .2em .6em .5em;
		border-radius: 0 4px 4px 0;
	}
	.searchsubmit:hover {
		background: $brand-tertiary;
		color: #fff;
	}
	.fa {
		padding: 0;
	}
	&:after {
		content: "";
		clear: both;
		display: block;
	}
}
/* ---------------------------------------- */