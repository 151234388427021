/* .main { padding-left: 0; padding-right: 0; } */
body.sidebar-primary .wrap.sidebarleft .main, body.sidebar-primary .wrap.sidebarright .main { padding-left: 15px; padding-right: 15px; }
body.sidebar-primary .wrap.sidebarleft .main { float: right; }
body.sidebar-primary .wrap.sidebarleft .sidebar { margin-right: 0; padding-right: ($grid-gutter-width / 2); }

.sidebar {
	float: right; 
 	font-size: 1em;
	background: #fff;
	.widget {
		margin-bottom: 3em; 
		padding: 1em 1.25em;
		color: #494949;
		font-family: $body-font;
		box-shadow: 0 0 8px rgba(0,0,0,.08);
		h1, h2, h3, h4 { 
			font-family: $brand-font; 
			font-weight: 600; 
			@include anti-alias; 
		}
		h4 {
			margin: -1em -.9em .5em;
			padding: .5em .75em;
			color: #fff;
			font-size: 1.4em;
			background: $brand-primary;
			position: relative;
			z-index: 1;
			&:after { 
				top: -14px;
				right: 0;
				position: absolute;
				width: 100%;
				height: calc(100% + 14px);
				background: url('../images/corner-motif.png') no-repeat top right;
				content: '';
				z-index: -1;
			}
			i.fa { padding-right: .3em; }
			a { 
				color: #fff !important; 
				position: relative;
				z-index: 1;
				&:hover { color: #fff !important; } 
			}
		}
    
		ul { 
			margin: 1em 0 0; 
			padding: 0;
			li { 
				padding: 0.25em 0;
				display: list-item; 
				list-style-type: none; 
				font-size: 1em;
			}
			li:last-child { border-bottom: none; }
		}
		ul li li {
			padding-left: 1.3em;
			a { 	
				position: relative; 
				&:before { 
					font-size: .8em; 
					content: "\f105"; /* fa-angle-right */
					font-family: 'FontAwesome'; 
					color: rgba($brand-quaternary,.4); 
					position: absolute; 
					top: -.1em; 
					left: -1.2em; 
				}
			}
		}
		ul.children {
			margin: 0.25em 0 0;
			padding: 0;
		}
			
		ul.list-style-none { padding-left: .2em; li { list-style-type: none; } }
    
		a { color: $brand-secondary !important; text-decoration: none; padding: 0; }
		a:visited { color: lighten(tint($brand-primary,20%),7%); }
		a:hover, a:focus { color: $brand-secondary !important; text-decoration: none; background: none; }
	}
		
	.widget.documents {
		// h4 {
        //     color: $brand-tertiary;
        //     a {
        //         color: $brand-tertiary !important;
        //     }
        // }
		ul {
			li a { 
				display: block;
				/*
				position: relative;
				&:before { 
					font-size: .8em; 
					content: "\f0f6"; // fa-file-text-o
					font-family: 'FontAwesome'; 
					color: rgba($brand-tertiary,.4); 
					position: absolute; 
					top: .2em; 
					left: -1.5em; 
				}
				*/
			}
		}
	}
		
	.search-form {
		position: relative;
		> label, input.search-field { width: 100%; height: 36px; }
		.search-submit { 
			margin: 0; 
			padding-top: 7px; 
			padding-bottom: 6px; 
			position: absolute; 
			right: 0; 
			top: 0; 
			border-radius: 0 5px 5px 0;
			height: 36px;
		}
	}
		
}


@media (max-width: $screen-sm-max) {
	body.sidebar-primary .wrap.sidebarleft .main, body.sidebar-primary .wrap.sidebarleft .sidebar,
	body.sidebar-primary .wrap.sidebarright .main, body.sidebar-primary .wrap.sidebarright .sidebar,
	.main, .sidebar,
	.sidebar-primary .main {
		float: none;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		padding-left: 15px;
		padding-right: 15px;
	}
	body.page-template-template-home #body-wrap .wrap .main,
	body.page-template-template-full-width #body-wrap .wrap .main, {
		padding-left: 0;
		padding-right: 0;
	}
}