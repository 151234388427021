/* CORE Styles -------------------------------------------------------- */
body {
	color: $body-color;
	font-family: $body-font;
	font-weight: 300;
	@include anti-alias;
}

p { margin-bottom: 1em; }


/* HEADINGS */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {	
	font-family: $body-font;
	font-weight: 600;
}

h1, .h1, h2, .h2, h3, .h3 {
	margin: 0 0 .3em;
	color: $brand-primary;
}

h4, .h4, h5, .h5, h6, .h6 { 
	margin: .8em 0 .4em; 
	color: $brand-tertiary; 
}

h1 { font-size: 2.2em; }
h2 { font-size: 2em; }
h3 { font-size: 1.8em; }
h4 { font-size: 1.6em; }
h5 { font-size: 1.4em; }
h6 { font-size: 1.2em; }
/* ---- */


/* LINKS */
a { 
	color: $brand-secondary;
	border-bottom: none;
	text-decoration: none; 
	&:focus, &:hover {
		color: lighten($brand-secondary,15%);
		text-decoration: none; 
	}
	&:visited { color: darken($brand-secondary, 10%); }
}
/* ---- */


/* LEADS */
p.lead, blockquote { 
	font-family: $body-font;
	color: $brand-secondary;
	font-size: 1.5em; 
	line-height: 1.3em;
	font-weight: 300;
	@include anti-alias;
}
blockquote { 
	border-left: 7px solid $brand-secondary;
	padding: .4em .75em; 
}

p.leaddropcap { 
	font-family: $body-font;
	color: $brand-secondary;
	font-size: 1.5em; 
	line-height: 1.3em;
	font-weight: 300;
	@include anti-alias;
}
/* ---- */


/* MISC */
.caps { text-transform: uppercase; }

.primary { color: $brand-primary !important; }
.secondary { color: $brand-secondary !important; }
.secondary-dark { color: shade($brand-secondary, 20%) !important; }
.tertiary { color: $brand-tertiary !important; }
.tertiary-dark { color: shade($brand-tertiary, 20%) !important; }
.quaternary { color: $brand-quaternary !important; }
.quaternary-dark { color: shade($brand-quaternary, 20%) !important; }

.underlined {
	margin: 0 0 .5em;
	display: table;
	color: $brand-primary;
	a { color: $brand-primary; &:hover { color: $brand-secondary; text-decoration: none; } }
	&:after {
		display: block;
		border: 1px solid $brand-secondary;
		width: 100%;
		margin-top: .2em;
		content: "";
	}
}

hr {
	margin: 6% 0;
	border: 0 none;
	height: 1px; 
	overflow: hidden;
	background: rgba($brand-quaternary,.15);
}
/* ---- */