/* CTA
.cta { 
	.cta-content { 
		position: relative;
		text-align: center;
		color: #fff;
		background: tint($brand-primary,40%);
		> .container {
			position: relative;
			z-index: 1;
		}
	}
	h1,h2,h3,h4,h5,h6 {
		color: #fff;
	}
	h1 { font-size: 2.6em; }
	h2 { font-size: 2.3em; }
	h3 { font-size: 2em; }
	h4, h5, h6 { 
		margin: 0 0 1em;
		font-family: $body-font; 
		font-style: italic;
		font-weight: 100; 
	}
	.cta-caption {
		padding: 2em 0 1em;
	}
	
	.cta1 { }
	.cta2 { }
	.cta1.cta-half { width: 50%; left: 0; }
	.cta2.cta-half { width: 50%; right: 0; }
	
	.cta-image {
		position: absolute;
		top: 0; 
		height: 100%; 
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		opacity: .1;
	}
	
}

@media (max-width: $screen-sm-max) {
	.cta {
		.cta1.cta-half, .cta2.cta-half { width: 100%; }
		.cta2.cta-half { display: none; }
		.cta-content {
			> div:first-child { padding-bottom: 0; }
			.cta-caption { padding: 2em 0; }
		}
	}
}
/* -- */


/* Footer */
footer#footer {	
	.col { margin-bottom: 1.5em; }
	img { max-width: 100%; }
	h1, h2, h3, h4, h5, h6 { font-family: $body-font; color: #fff; }
	h4 { font-size: 1.6em; margin: 0 0 .75em; }
	a { 
		color: lighten($brand-secondary,15%); 
		text-decoration: none; 
		&:hover { text-decoration: underline; } 
	}
	ul {
		padding-left: 0;
		list-style: none;
		li { list-style-position: inside; }
		li.disable { display: none; }
	}
	.footer-upper {
		font-size: .95em;
		line-height: 1.35em;
		color: #fff;
		background: $brand-quaternary;
		.container {
			padding-top: 3em;
		}
		.menu li {
			list-style-type: none;
			color: $brand-primary; 
			padding-bottom: .5em;
			.caret { display: none; }
			a { color: lighten($brand-secondary,15%); }
		}
		.quick-links-nav > a { display: none; }
	}
	.footer-lower {
		padding: .8em 0;
		background: $brand-quaternary;
		color: rgba(#fff,.5);
		line-height: 1.2em;
	}
	.map-embed { display: none; }
	.connect-with-us {
		.social { margin-bottom: 1em; }
		a { display: inline-block; }
	}
	.logo { 
		text-align: center;
		img { width: 60%; }
	}
	.site-copyright, .site-credit {
		font-size: .8em;
		a { color: rgba(#fff,.5); }
	}
	.site-credit { text-align: right; }
	#menu-footer li { 
		display: inline-block; 
		border-right: 1px solid rgba(#fff,.3);
		a { 
			padding: .2em .4em;
			color: rgba(#fff,.5);
			text-decoration: underline;
			font-size: .9em;
		}
		&:last-child { border: none; }
	}
}

@media (max-width: $screen-sm-max) {
	footer#footer {
		.slogan { font-size: 1.6em; }
		.site-credit { text-align: left; }
	}
}
@media (max-width: $screen-xs-max) {
	footer#footer {
		&:before {
			background-position: -20% 220%;
			background-size: 800px;
		}
		.col { padding-top: 1em; border-top: 1px solid rgba(#fff,.15); }
		.row .col:nth-child(1), .row .col:nth-child(2) { padding-top: 0; border: none; }
	}
}
/* -- */